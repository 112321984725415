import React from "react"

import Layout from "../components/layout"

const OurteamPage = () => (
	<Layout activePath='our-team' pageTitle="Our Team Members">
		
	</Layout>
)

export default OurteamPage
